import "./Home.css";
import React, { useState } from "react";
import Map from "./Map";
import axios from "axios";

function Home({ handleBookingSystemOpenClick, width }) {
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailAdded, setEmailAdded] = useState(false);

  const [apiKey, setApiKey] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(`/google/googleMapsApiKey`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((response) => {
        setApiKey(response.data.key);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleEmailInput = (e) => {
    setEmail(e.target.value);

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value) &&
      e.target.value.length > 0
    ) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }
  };

  async function handleEmailSubmit() {
    if (email.length === 0 || invalidEmail === true) {
      window.alert("Email is not valid!");
    } else {
      try {
        const emailData = {
          email,
        };
        await axios.post("/db/add-email", emailData, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });
        setEmailAdded(true);
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="mandala-container">
        <div
          className={`welcome-container ${
            width < 700 ? "welcome-container-resized" : ""
          }`}
        >
          <div className="welcome-text-container">
            <span
              className={`recoleta medium ${
                width < 450 ? "center text-center" : ""
              }`}
            >
              Celebrate your moment
            </span>
            <div className="flex-column">
              <span
                className={`recoleta big ${
                  width < 450 ? "center text-center" : ""
                }`}
              >
                Therapeutic
              </span>
              <span
                className={`recoleta big ${
                  width < 450 ? "center text-center" : ""
                }`}
              >
                Massage & Yoga
              </span>
            </div>
            <span
              className={`small ${width < 450 ? "center text-center" : ""}`}
            >
              Simple, affordable, memorable
            </span>

            <div
              className={`${
                width < 450 ? "flex-row justify-content-center" : ""
              }`}
            >
              <div className={`book`} onClick={handleBookingSystemOpenClick}>
                <span className={`small`}>Book</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          width < 1152
            ? width < 629
              ? "location-map-container flex-column location-map-resized"
              : "location-map-container flex-column"
            : "location-map-container"
        }`}
      >
        <div
          className={`${
            width < 629
              ? "location-hours-text-container flex-column"
              : "location-hours-text-container flex-row"
          }`}
        >
          <div className="location-container flex-column">
            <span className="recoleta medium">Location</span>
            <span className="small">
              Laura Martinez Therapeutic Massage & Yoga
            </span>
            <span className="small">4478 Greystone Place Court</span>
            <span className="small">Winston Salem, North Carolina 27106</span>
            <span className="small">(336) 552-0796</span>
            <span className="small">laura.martinez.massage.yoga@gmail.com</span>
          </div>
          <div className="hours-container flex-column">
            <span className="recoleta medium">Hours</span>
            <span className="small">Monday 6:00 am - 7:45 pm</span>
            <span className="small">Tuesday 6:00 am - 7:45 pm</span>
            <span className="small">Wednesday 6:00 am - 7:45 pm</span>
            <span className="small">Thursday 6:00 am - 7:45 pm</span>
            <span className="small">Friday 6:00 am - 7:45 pm</span>
            <span className="small">Saturday 11:00 am - 12:45pm</span>
            <span className="small">Sunday Closed</span>
          </div>
        </div>
        <Map apiKey={apiKey} />
      </div>

      <div className="email-container flex-column">
        <span className="recoleta medium">Stay in the loop</span>
        <span className="small">Get the latest news when you sign up</span>

        {emailAdded ? (
          <div>
            <span>Email added!</span>
          </div>
        ) : (
          <div className="flex-row">
            <input
              className={`${invalidEmail ? "email-not-complete" : ""} ${
                width < 609 ? "email-input email-input-resize" : "email-input"
              }`}
              type="text"
              placeholder="Email"
              spellCheck={false}
              value={email}
              onInput={handleEmailInput}
            />
            <div className="sign-up" onClick={handleEmailSubmit}>
              <span className="small">Sign Up</span>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Home;
